import Head from 'next/head';
import { useState } from 'react';
import LoadingSpinner from 'components/loading/LoadingSpinner';
import MobileMenu from 'components/headers/MobileMenu';
import Header from 'components/headers/Header';
import { useProtectedUrlGuard } from 'auth/useProtectedUrlGuard';
import SMSCapture from 'components/sms/SMSCapture';
import Footer from '../Footer';

import NotFound from '../../NotFound';

const DefaultLayout = ({
  hideFooter = false,
  ownerNav = false,
  captainNav = false,
  noFollow = true,
  children = null,
  white = false,
  hideNav = false,
  hideHeader = false,
  isHome = false,
  isSearch = false,
  name = '',
  path = undefined,
  isPdp = false,
  Component = undefined,
  is404 = false,
  isProtected = false,
  customFooter = null,
  loading = false,
  ...props
}) => {
  const getFooter = () => {
    if (customFooter && !hideFooter) return customFooter;
    if (!hideFooter) return <Footer />;
  };

  const [menuOpen, setMenuOpen] = useState(false);

  useProtectedUrlGuard(isProtected);

  const getComponent = () => {
    if (loading)
      return (
        <div style={{ minHeight: '80vh' }}>
          <div
            style={{
              position: 'fixed',
              top: '0px',
              left: '0px',
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LoadingSpinner size="medium" color="#72D4BA" />
          </div>
        </div>
      );
    if (is404) return <NotFound statusCode={404} />;
    if (Component && !children) return <Component />;
    if (!Component && children) return children;
  };

  return (
    <>
      {noFollow && (
        <Head>
          <meta name="robots" content="noindex, nofollow" />
        </Head>
      )}
      <div id="root3" style={{ background: '#fff' }} className={`page-${name || path?.replace('/', '')}`}>
        {!hideHeader && (
          <>
            <MobileMenu
              hideNav={hideNav}
              white={white}
              isSearch={isSearch}
              isHome={isHome}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              {...props}
            />
            <Header
              isPdp={isPdp}
              hideNav={hideNav}
              isSearch={isSearch}
              white={white}
              isHome={isHome}
              ownerNav={ownerNav}
              captainNav={captainNav}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              {...props}
            />
          </>
        )}
        {getComponent()}
        {getFooter()}
      </div>
      <SMSCapture />
    </>
  );
};

export default DefaultLayout;
