import { useState, useEffect, SyntheticEvent } from 'react';
import useRudderStackTrack from 'hooks/useRudderStackTrack';
import useLocalStorageExpirationHours from 'hooks/useLocalStorageExpirationHours';
import useCountdown from 'hooks/useCountdown';
import { useUserDetails } from 'auth/mutations/userHooks';
import {
  useGetUserPreferenceQuery,
  useUpdateNotificationPreferencesMutation,
} from 'components/subscriptionPreferences/hooks';
import boatsetterPaths from 'utils/boatsetterPaths';

import { useRouter } from 'next/router';

const SMS_EVENTS = {
  EVENT_NAME: 'sms_marketing_capture',
  SUBSCRIBED: 'subscribed',
  NO_ACTION: 'modal_closed_no_action',
} as const;

const noToken = undefined;

export default function useSMSCaptureMutation() {
  const { isAuthenticated, userId } = useUserDetails();
  const countdown = useCountdown(2000, 250);
  const { expirationStatus, setExpiration, clearExpiration } = useLocalStorageExpirationHours(
    SMS_EVENTS.EVENT_NAME,
    48
  );
  const [open, setOpen] = useState<boolean>(false);
  const { notificationPreferences, isLoading: isFetchingNotificationPreferences } = useGetUserPreferenceQuery(noToken);
  const [verificationComplete, setVerificationComplete] = useState<boolean>(false);
  const rudderStackTrack = useRudderStackTrack();
  const notificationPreferencesMutation = useUpdateNotificationPreferencesMutation();
  const router = useRouter();

  useEffect(() => {
    const isPage = (to) => router.asPath === to;
    switch (true) {
      case isPage('/') || isPage(boatsetterPaths.ownerDashboard) || isPage(boatsetterPaths.captainDashboard):
        setOpen(true);
        break;
      default:
        setOpen(false);
    }
  }, [router.asPath]);

  const handleSMSCapture = async (e: SyntheticEvent) => {
    e.preventDefault();
    // if we have their preferences set, do nothing
    if (notificationPreferences?.news_and_promotions_sms) {
      return;
    }
    await notificationPreferencesMutation.mutateAsync({
      body: {
        ...notificationPreferences,
        news_and_promotions_sms: true,
      },
      token: noToken,
    });
    setVerificationComplete(true);
    rudderStackTrack(SMS_EVENTS.EVENT_NAME, { trackingName: SMS_EVENTS.SUBSCRIBED, userId });
  };

  const close = () => {
    if (!verificationComplete) {
      rudderStackTrack(SMS_EVENTS.EVENT_NAME, { trackingName: SMS_EVENTS.NO_ACTION, userId });
    }
    setOpen(false);
    setExpiration();
  };

  const checkIfOpen = () => {
    if (!isAuthenticated) {
      return false;
    }
    if (!countdown.done || !open) {
      return false;
    }
    if (verificationComplete) {
      return true;
    }
    if (expirationStatus === 'pending' || expirationStatus === 'in-range') {
      return false;
    }
    if (expirationStatus === 'error' || expirationStatus === 'expired') {
      clearExpiration();
    }
    const hasSMS = !!notificationPreferences?.news_and_promotions_sms;
    const shouldOpenSMSModal = !hasSMS ? open : false;
    return shouldOpenSMSModal;
  };

  return {
    handleSMSCapture,
    isLoading: isFetchingNotificationPreferences || notificationPreferencesMutation.isLoading,
    open: checkIfOpen(),
    close,
    phone: notificationPreferences?.user?.phone ?? '',
    verificationComplete,
  };
}
