import { AccountApi } from 'swagger/apis/account-api';
import OpenApiConfiguration from 'api/OpenApiConfiguration';
import { useRouter } from 'next/router';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { DomesticV2MeNotificationPreferencesGetRequest } from 'swagger/models';

const accountApi = new AccountApi(OpenApiConfiguration);

export const useGetUserPreferenceQuery = (
  token: string | undefined,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof accountApi.domesticV2MeNotificationPreferencesGet>>,
      AxiosError<any, any>
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const router = useRouter();

  const { data: { data: notificationPreferences } = {}, ...rest } = useQuery(
    ['notificationPreferences', token],
    () =>
      accountApi.domesticV2MeNotificationPreferencesGet({
        params: {
          token,
        },
      }),
    {
      ...options,
      retry: 1,
      enabled: router.isReady,
    }
  );

  return {
    notificationPreferences,
    ...rest,
  };
};

export const useUpdateNotificationPreferencesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: { body: DomesticV2MeNotificationPreferencesGetRequest; token: string }) =>
      accountApi.domesticV2MeNotificationPreferencesPatch(body.body, {
        params: {
          token: body.token,
        },
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['notificationPreferences']);
      },
    }
  );
};
