import { SyntheticEvent } from 'react';
import * as SMS from './sms.styles';
import useSMSCapture from './useSMSCapture';

type SMSCaptureCloseButtonProps = {
  close: () => void;
};

const SMSCaptureCloseButton = ({ close }: SMSCaptureCloseButtonProps) => (
  <SMS.CaptureCloseButtonContainer>
    <SMS.CaptureCloseButton onClick={close}>
      <SMS.CaptureCloseIconContainer />
      <SMS.CaptureCloseIcon />
    </SMS.CaptureCloseButton>
  </SMS.CaptureCloseButtonContainer>
);

type SMSVerifcationBeginProps = {
  phone: string;
  isLoading: boolean;
  handleSMSCapture: (e: SyntheticEvent) => void;
};

const SMSVerifcationBegin = ({ phone, isLoading, handleSMSCapture }: SMSVerifcationBeginProps) => (
  <>
    <SMS.CaptureTitle>Stay in the know ⚓</SMS.CaptureTitle>
    <SMS.CaptureSubtext>
      Receive updates via SMS on the latest news and promotions to{' '}
      <SMS.CaptureSubtextSpan>{phone}</SMS.CaptureSubtextSpan>
    </SMS.CaptureSubtext>
    <SMS.CaptureDisclosure>
      By selecting SUBSCRIBE I consent to receive promotional marketing materials via an automated SMS text messaging
      system to the telephone number I have provided for my account. Consent is not a condition to register for an
      account or purchase any services. View Boatsetter&rsquo;s{' '}
      <a href="/legal/terms_of_service#sms_terms_of_service">
        <SMS.CaptureDisclosureSpan>SMS Texting Policy.</SMS.CaptureDisclosureSpan>
      </a>
    </SMS.CaptureDisclosure>
    <SMS.CaptureButton disabled={isLoading} onClick={(e: SyntheticEvent) => handleSMSCapture(e)}>
      <SMS.CaptureButtonText>Subscribe</SMS.CaptureButtonText>
    </SMS.CaptureButton>
  </>
);

const SMSVerifcationComplete = () => (
  <SMS.VerificationCompleteContainer>
    <SMS.VerificationCompleteIcon />
    <SMS.VerificationCompleteText>You&rsquo;re in! Enjoy promotions and updates via SMS!</SMS.VerificationCompleteText>
  </SMS.VerificationCompleteContainer>
);

export default function SMSCapture() {
  const sms = useSMSCapture();
  return sms.open ? (
    <SMS.CaptureContainer>
      <SMSCaptureCloseButton close={sms.close} />
      {sms.verificationComplete ? <SMSVerifcationComplete /> : <SMSVerifcationBegin {...sms} />}
    </SMS.CaptureContainer>
  ) : null;
}

/*
Your can reset below by adding this button to the return statement above instead of null:
<SMS.CaptureContainer>
  <button style={{ width: '200px', height: '200px', backgroundColor: 'fuchsia' }} onClick={sms.reset}>
    RESTART!
  </button>
</SMS.CaptureContainer>
*/
