import styled from 'styled-components';

export const CaptureContainer = styled.div`
  display: flex;
  width: 429px;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  border: 1px solid #dbdfe5;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999;
`;

export const CaptureCloseButtonContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: -20px;
  margin-left: 20px;
`;

export const CaptureCloseButton = styled.button`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  position: absolute;
  top: 0px;
  right: 0px;
`;

export const CaptureCloseIconContainer = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <circle cx="16" cy="16" r="16" fill="#F5F7F9" />
  </svg>
);

export const CaptureCloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      position: 'fixed',
      marginTop: '10px',
      marginLeft: '-22px',
    }}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <rect y="1.41431" width="2" height="14" rx="1" transform="rotate(-45 0 1.41431)" fill="#5E696A" />
    <rect
      x="1.41406"
      y="11.3137"
      width="2"
      height="14"
      rx="1"
      transform="rotate(-135 1.41406 11.3137)"
      fill="#5E696A"
    />
  </svg>
);

export const CaptureTitle = styled.p`
  width: 329px;
  color: #17233c;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Avenir Next;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px; /* 127.778% */
  margin-top: revert;
`;

export const CaptureSubtext = styled.p`
  color: #17233c;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Avenir Next;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
`;

export const CaptureSubtextSpan = styled.span`
  color: #17233c;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Avenir Next;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;

export const CaptureDisclosure = styled.p`
  color: var(--neutral-neutral-dark, #5e696a);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Avenir Next;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 13px */
`;

export const CaptureDisclosureSpan = styled.span`
  color: var(--primary-water-renter-primary, #0751c2);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Avenir Next;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;

export const CaptureButton = styled.button`
  display: flex;
  width: 349px;
  height: 56px;
  padding: 0px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--water-renter-primary, #0751c2);
  color: #fff;
`;

export const CaptureButtonText = styled.span`
  color: var(--White, #fff);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Avenir Next;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 18.2px */
  text-transform: uppercase;
`;

export const VerificationCompleteContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
`;

export const VerificationCompleteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
    <path
      d="M18.5 0.5C8.56834 0.5 0.5 8.58634 0.5 18.4999C0.5 28.4315 8.58643 36.4999 18.5 36.4999C28.4316 36.4999 36.5 28.4135 36.5 18.4999C36.5181 8.56825 28.4316 0.5 18.5 0.5ZM18.5 33.7863C10.0698 33.7863 3.21357 26.93 3.21357 18.4999C3.21357 10.0698 10.0698 3.21357 18.5 3.21357C26.9301 3.21357 33.7864 10.0698 33.7864 18.4999C33.8045 26.93 26.9301 33.7863 18.5 33.7863ZM26.8216 12.729C27.3462 13.2718 27.3281 14.122 26.8035 14.6466L16.908 24.2889C16.6367 24.5421 16.311 24.6688 15.9673 24.6688C15.6236 24.6688 15.2799 24.5421 15.0085 24.2889L10.2327 19.5854C9.68994 19.0608 9.68994 18.1924 10.2146 17.6678C10.7392 17.1432 11.6075 17.1251 12.1322 17.6497L15.9673 21.4125L24.9221 12.6929C25.4286 12.1864 26.297 12.1863 26.8216 12.729Z"
      fill="#2A8500"
    />
  </svg>
);

export const VerificationCompleteText = styled.p`
  width: 287px;
  color: #17233c;
  text-align: center;
  font-family: Avenir Next;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
`;
