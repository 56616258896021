import { useState, useEffect, useCallback } from 'react';

type UseCountdownReturn = {
  reset: () => void;
  done: boolean;
};

const useCountdown = (initialMilliseconds: number, reduceBy = 500): UseCountdownReturn => {
  const [remainingTime, setRemainingTime] = useState<number>(initialMilliseconds);
  const [done, setDone] = useState<boolean>(false);

  useEffect(() => {
    if (remainingTime <= 0) {
      setDone(true);
      return;
    }

    const intervalId = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - reduceBy);
    }, reduceBy);

    // eslint-disable-next-line consistent-return
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [remainingTime, reduceBy]);

  const reset = useCallback(() => {
    setDone(false);
    setRemainingTime(initialMilliseconds);
  }, [initialMilliseconds]);

  return {
    reset,
    done,
  };
};

export default useCountdown;
