import { useEffect } from 'react';
import styled from 'styled-components';
import { mediaMSmMax, mediaMXsMax } from './helpers/breakpoints';
import useRudderStackTrack from './hooks/useRudderStackTrack';

export default ({ statusCode = undefined }) => {
  const rudderTrack = useRudderStackTrack();

  useEffect(() => {
    rudderTrack('js_blimey', {
      statusCode: statusCode || 'logical_error',
    });
  }, []);

  return (
    <Wrapper>
      <Flex>
        <Img alt={statusCode || '404'} src="/images/kraken.png" />
        <TextWrapper>
          <Heading>
            Uh oh! {statusCode && `Error: ${statusCode}`} <br />
            Something went wrong
          </Heading>
        </TextWrapper>
      </Flex>
    </Wrapper>
  );
};

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Heading = styled.h1`
  margin-bottom: 0;
  font-weight: 700;
`;

const Img = styled.img`
  width: 40%;
  height: auto;
  ${mediaMXsMax} {
    width: 50%;
  }
`;

const Flex = styled.div`
  display: flex;
  gap: 20px;
  ${mediaMXsMax} {
    align-items: center;
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  max-width: 1140px;
  padding: 80px 15px;
  margin: 0 auto;
  ${mediaMSmMax} {
    padding: 120px 15px 50px 15px;
  }
`;
