import Bugsnag from '@bugsnag/js';
import { useState, useEffect, useCallback } from 'react';

type ExpirationStatus = 'pending' | 'expired' | 'error' | 'in-range' | 'none';

type UseLocalStorageExpirationHoursReturn = {
  expirationStatus: ExpirationStatus;
  setExpiration: () => void;
  clearExpiration: () => void;
};

const useLocalStorageExpirationHours = (localStorageKey: string, hours = 48): UseLocalStorageExpirationHoursReturn => {
  const [expirationDate, setExpirationDate] = useState<Date | null>(null);
  const [expirationStatus, setExpirationStatus] = useState<ExpirationStatus>('pending');

  const setExpiration = useCallback(() => {
    const futureDate = new Date();
    futureDate.setHours(futureDate.getHours() + hours);
    localStorage.setItem(localStorageKey, futureDate.toISOString());
    setExpirationDate(futureDate);
  }, [localStorageKey, hours]);

  useEffect(() => {
    try {
      const storedDate = localStorage.getItem(localStorageKey);
      if (storedDate) {
        const date = new Date(storedDate);
        if (Number.isNaN(date.getTime())) {
          throw new Error('Invalid date in localStorage');
        }
        setExpirationDate(date);
      }
    } catch (error) {
      Bugsnag.notify(error);
      setExpirationDate(new Date('Invalid')); // This will trigger the 'error' state
    }
  }, [localStorageKey, setExpiration]);

  useEffect(() => {
    if (!expirationDate) {
      setExpirationStatus('none');
      return;
    }
    if (Number.isNaN(expirationDate?.getTime())) {
      setExpirationStatus('error');
      return;
    }
    const now = new Date();
    const timeDifference = now.getTime() - expirationDate.getTime();
    const timeDifferenceInHours = timeDifference / (1000 * 60 * 60);
    const status = timeDifferenceInHours > hours ? 'expired' : 'in-range';
    setExpirationStatus(status);
  }, [expirationDate, hours]);

  const clearExpiration = useCallback(() => {
    localStorage.removeItem(localStorageKey);
    if (expirationDate) setExpirationDate(null);
  }, [localStorageKey]);

  return {
    expirationStatus,
    setExpiration,
    clearExpiration,
  };
};

export default useLocalStorageExpirationHours;
